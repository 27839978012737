import React from 'react'

import { MotionConfig } from 'framer-motion'
import { Helmet } from 'react-helmet'

import Header from '../components/Header'
import StartHere from '../components/StartHere'

const videos = () => {
  return (
    <MotionConfig reducedMotion='user'>
      <Helmet>
        <title>Cole Zesiger - Relationship Advisor</title>
        <meta
          name='description'
          content='Cole Zesiger helps couples create happier and deeper relationships.'
        />
      </Helmet>
      <div className='pt-12'>
        <div className='container px-8 mx-auto'>
          <Header />
          <div className='p-4 md:p-8 glass'>
            <StartHere />
          </div>
          <h2 className='my-12 text-5xl font-bold text-center text-gray-400'>
            More coming soon!
          </h2>
        </div>
      </div>
    </MotionConfig>
  )
}

export default videos
