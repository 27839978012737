import React from 'react'
import { motion } from 'framer-motion'
import thumbnail from '../images/emergencybreakupcourse.jpg'
import thumbnailWebp from '../images/emergencybreakupcourse.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

const StartHere = () => {
  const videoAnim = {
    initial: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    hover: {
      backgroundColor: 'rgba(0, 0, 0, 0.0)',
    },
  }

  const playAnim = {
    initial: {
      scale: 1,
    },
    hover: {
      scale: 1.5,
    },
  }

  return (
    <div className='flex flex-col gap-12 md:mx-48 md:flex-row'>
      <div className='flex-1'>
        <a href='https://www.youtube.com/watch?v=c-SkuMykSlo' target='_blank'>
          <div className='relative overflow-hidden border-4 border-blue-300 shadow-xl cursor-pointer rounded-2xl'>
            <picture>
              <source srcSet={thumbnailWebp} type='image/webp' />
              <motion.img src={thumbnail} alt='Free Emergency Breakup Course' />
            </picture>
            <motion.div
              className='absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full'
              variants={videoAnim}
              initial='initial'
              whileHover='hover'
            >
              <motion.div
                variants={playAnim}
                initial='initial'
                whileHover='hover'
                style={{
                  opacity: '100%',
                }}
              >
                <FontAwesomeIcon
                  icon={faPlay}
                  size='6x'
                  className='shadow-lg'
                />
              </motion.div>
            </motion.div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default StartHere
